import React from 'react';
import githubIcon from './images/icons/github-icon.png';
import greenGithubIcon from './images/icons/github-icon-green.png';
import newTabIcon from './images/icons/new-tab-icon.png';
import greenNewTabIcon from './images/icons/new-tab-icon-green.png';
import './App.scss';

function App() {
  const skillsList = ['html', 'sass', 'typescript', 'react', 'express', 'nodejs', 'mongodb'];

  const handleMouseEnter = (id: string): void => {
    const img = document.getElementById(id) as HTMLImageElement;
    if (id.includes("code")) {
      img.src = greenGithubIcon;
    } else if (id.includes("newtab")) {
      img.src = greenNewTabIcon;
    }
  };
  const handleMouseLeave = (id: string): void => {
    const img = document.getElementById(id) as HTMLImageElement;
    if (id.includes("code")) {
      img.src = githubIcon;
    } else if (id.includes("newtab")) {
      img.src = newTabIcon;
    }
  };
  
  return (
    <div className="App">
      <header className="header">
        <h1>Bryce Cooper</h1>
        <nav className="nav-menu">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#projects">Projects</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
      </header>

      <main>
        <section className="main-section">
          <div className="main-grid">
            <div className="main-content">
              <div className="main-title">
                Full-Stack Software Developer
                <img className="main-image" src="./images/icons/hand-waving.png" alt="Hand Waving"/>
              </div>
              <div>
                Hi, I'm Bryce Cooper. A passionate Full-Stack Software Developer based in Provo, Utah.
                <img className="red-pin" src="./images/icons/red-pin.png" alt="Red Pin" />
              </div>
              <div>
                <a href="https://www.linkedin.com/in/brycedcooper">
                  <img className="social-icon icon" src="/images/social/linkedin-icon.png" alt="LinkedIn Icon" />
                </a>
                <a href="https://github.com/BryceCoop99">
                  <img className="social-icon icon github" src={githubIcon} alt="Github Icon" />
                </a>
              </div>
            </div>
            <img className="profile-image" src="/images/me-transparent.png" alt="My Profile" />
          </div>

          <div className="tech-stack">
            Tech Stack
            <div className="tech-skills">
            {skillsList.map((skill) => (
              <img key={skill} className="icon" src={`images/skills/${skill}-icon.png`} alt={skill} />
            ))}
            </div>
          </div>
        </section>

        <section id="about" className="grid-section">
          <img className="about-image" src="/images/coding.gif" alt="Me Coding"/>
          <div className="grid-column">
            <div className="section-title">About Me</div>
            <div className="grid-title">
              A dedicated Full-Stack Software Developer based in Provo, Utah
              <img className="red-pin" src="./images/icons/red-pin.png" alt="Red Pin"/>
            </div>
            <div className="grid-desc">
              As a Full-Stack Software Developer I:
              <ul>
                <li>Prosper in AWS Cloud and REST APIs</li>
                <li>Possess an arsenal of skills in both SQL and NoSQL</li>
                <li>Excel in software design patterns and algorithms</li>
                <li>Thrive as a team player to produce outstanding results</li>
              </ul>
            </div>
          </div>
        </section>
        
        <section id="projects">
          <div className="section-title">Portfolio</div>
          <div className="grid-title">
            Each project is a unique piece of development
            <img className="inline-icon" src="/images/icons/puzzle-piece.png" alt="Puzzle Piece"></img>
          </div>
          <div className="project">
            <img className="project-image" src="images/projects/simplycited.png" alt="Project: SimplyCited" />
            <div className="project-grid">
              <h3>
                SimplyCited
                <img className="inline-icon" src="/images/icons/simplycited-icon.png" alt="SimplyCited Project"/>
              </h3>
              <span>
                SimplyCited is a tool for APA or MLA formatted essays. At the click of a button, you are 
                provided with corrections and suggestions for your entire essay. A simple way to double
                check your paper's format and citations!
              </span>
              <ul>
                <li>TypeScript</li>
                <li>React</li>
                <li>MongoDB</li>
              </ul>
              <ul>
                <li>
                  <a className="icon-link"
                  href="https://github.com/BryceCoop99/SimplyCited"
                  onMouseEnter={() => handleMouseEnter("code1")}
                  onMouseLeave={() => handleMouseLeave("code1")}
                  target="_blank"
                  rel="noreferrer">
                    Code
                    <img className="icon github" id="code1"
                    src={githubIcon}
                    alt="Github Icon"/>
                  </a>
                </li>
                <li>
                  <a className="icon-link"
                  href="https://www.simplycited.com/"
                  onMouseEnter={() => handleMouseEnter("newtab1")}
                  onMouseLeave={() => handleMouseLeave("newtab1")}
                  target="_blank"
                  rel="noreferrer">
                    Live Demo
                    <img className="icon new-tab" id="newtab1"
                    src={newTabIcon}
                    alt="Github Icon"/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="project">
          <div className="project-grid">
              <h3>
                BestTravel
                <img className="inline-icon" src="/images/icons/besttravel-icon.png" alt="BestTravel Project"/>
              </h3>
              <span>
                BestTravel, discounted prices for airline tickets to your favorite destinations in one place.
                Marked as high as 50% discounted than normal prices to the USA, Brazil, and Latin American countries!
              </span>
              <ul>
                <li>JavaScript</li>
                <li>React</li>
                <li>Firebase</li>
              </ul>
              <ul>
                <li>
                  <a className="icon-link"
                  href="https://github.com/BryceCoop99/besttravel-app"
                  onMouseEnter={() => handleMouseEnter("code2")}
                  onMouseLeave={() => handleMouseLeave("code2")}
                  target="_blank"
                  rel="noreferrer">
                    Code
                    <img className="icon github" id="code2"
                    src={githubIcon}
                    alt="Github Icon"/>
                  </a>
                </li>
                <li>
                  <a className="icon-link"
                  href="https://www.besttravelus.com/"
                  onMouseEnter={() => handleMouseEnter("newtab2")}
                  onMouseLeave={() => handleMouseLeave("newtab2")}
                  target="_blank"
                  rel="noreferrer">
                    Live Demo
                    <img className="icon new-tab" id="newtab2"
                    src={newTabIcon}
                    alt="Github Icon"/>
                  </a>
                </li>
              </ul>
            </div>
            <img className="project-image" src="images/projects/besttravel.png" alt="Project: BestTravel" />
          </div>
        </section>

        <section id="contact">
          <h2>Contact</h2>
          <p>Email: brycecoop99@gmail.com</p>
          <p>Phone: (385) 343-7856</p>
        </section>
      </main>
      <footer className='footer'>
        <p>&copy; 2023 Bryce Cooper. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
